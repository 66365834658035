.fade-to-white {
  animation: fadeToWhite 2s forwards;
}

@keyframes fadeToWhite {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    background: white;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-out;
}

.shadow {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px !important;
}

/* .sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white; 
  text-align: center;
  padding: 50px 0;
  background-image: url("jag.svg");
  background-size: cover;
} */
.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px; /* Adjust based on your design */
  overflow: hidden;
}

#triangle-left,
#triangle-right {
  position: absolute;
  bottom: 0;
  width: 50vw; /* Adjust if necessary */
  height: 100%; /* Match footer height */
}

#triangle-left {
  left: 0;
  background: linear-gradient(
    to bottom left,
    transparent 50%,
    rgb(29, 46, 53) 50%
  );
}

#triangle-right {
  right: 0;
  background: linear-gradient(
    to bottom right,
    transparent 50%,
    rgb(29, 46, 53) 40%
  );
}

.largeMargin {
  margin: 15vh 0;
}

.triangle-corner-col {
  position: relative; /* Ensures the triangle is positioned relative to this container */
}

.triangle-corner-col::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid transparent; /* Adjust for size */
  border-right: 50px solid #fd0002; /* Triangle color and size; adjust as needed */
  -webkit-transform: rotate(90deg);
}

.triangle-corner-col-opp {
  position: relative; /* Ensures the triangle is positioned relative to this container */
}

.triangle-corner-col-opp::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid transparent; /* Adjust for size */
  border-left: 50px solid rgb(29, 46, 53); /* Triangle color and size; adjust as needed */
  -webkit-transform: rotate(270deg);
}

/* Timeline Container */
.timeline {
  margin: 20px auto;
  padding: 20px;
  position: relative; /* Needed for the shadow's positioning */
  height: 55vh;
  overflow-y: scroll;
}

/* Shadows */
.shadow-top {
  box-shadow: 0 1em 1em -1em black inset;
}

.shadow-bottom {
  box-shadow: 0 -2em 2em -2em black inset;
}

/* Combined if both are needed */
.shadow-both {
  box-shadow: 0 1em 1em -1em black inset, 0 -2em 2em -2em black inset;
}
/* Card container */
.card {
  position: relative;
  max-width: 600px;
  border: none;
  background-color: transparent;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #fd0002;
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -4.5px;
  bottom: -4.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  background: #1d2e35;
  color: white;
  padding: 10px;
}

/* Title of the card */
.title {
  color: #fd0002;
  position: relative;
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  /* border-radius: 999px; */
  border: 3px solid #fd0002;
}

/* text right if the card is even  */
.card:nth-child(even) > .info > .title {
  text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
  left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
  right: -45px;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.contact > a {
  color: white;
}

.accordion-button {
  background-color: #1d2e35;
  color: white;
  border: none;
  outline: none;
  border-color: transparent !important;
  box-shadow: none;
  font-size: 1.3rem;
}

.accordion-heading {
  font-size: 1.2rem;
}
.accordion-body {
  font-size: 1.2rem;
}
.accordion-button::after {
  background-image: url("./arrow.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-button :focus {
  color: white;

  border: none;
  outline: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #1d2e35;
  color: white;

  border: none;
  outline: none;
  border-color: transparent !important;
  box-shadow: none;
}

.accordion-collapse {
  border: none !important;
  box-shadow: none;
}

/* Default styles for large screens */
.contact-col {
  border-right: 2px solid white;
}

/* Adjustments for small screens */
@media (max-width: 991.98px) {
  /* Bootstrap's large screen breakpoint */

  .nonGalleryImage {
    max-width: 100%;
  }
  .contact-col {
    border-right: none; /* Remove the border */
    margin-bottom: 20px; /* Add some vertical margin */
  }

  .triangle-corner-col {
    position: relative; /* Ensures the triangle is positioned relative to this container */
  }

  .triangle-corner-col::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: none; /* Adjust for size */
    border-right: none; /* Triangle color and size; adjust as needed */
    -webkit-transform: rotate(90deg);
  }

  .triangle-corner-col-opp {
    position: relative; /* Ensures the triangle is positioned relative to this container */
  }

  .triangle-corner-col-opp::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: none; /* Adjust for size */
    border-left: none; /* Triangle color and size; adjust as needed */
    -webkit-transform: rotate(270deg);
  }
  .verticalSmall {
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
  }
  .smallAuto {
    margin: auto;
  }
  .smallNoBorder {
    border: none !important;
  }
}

.galleryImage {
  --s: 330px; /* control the size of the images/videos */

  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  transform-origin: var(--x) var(--y);
  transition: 1s calc((var(--i) - 1) * 0.1s);
}
.nonGalleryImage {
}
.gallery {
  --s: 330px; /* control the size of the images/videos */

  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 5px;
  position: relative;
}

.myLogoContainer {
  position: fixed;
  left: 20px;
  bottom: 15px;
  width: 10vw;
  height: 60px; /* Adjust based on your design */
}

.myLogo {
  height: 30px;
  width: 30px;
  z-index: 1111;
}
/* Additional styles for further nested items */
.nested-item {
  margin-left: 20px; /* Adjust as necessary for your desired level of indentation */
  padding-left: 10px; /* Optional: adds padding to the left for better visual spacing */
}
